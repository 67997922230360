
   .contain {
    display: flex;
    justify-content: center;
 
    margin: auto;
}
 
.flip-card {
    perspective: 1000px;
    width: 39rem;
    height: 24.5rem;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 1.5s;
}
 
.flip-card.flipped {
    transform: rotateY(180deg);
}
 
.flip-card-inner {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
}
 
.flip-card-front,
.flip-card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
}
 
.flip-card-front {
    background-color: #EBEDFF;
    /* color: #EBEDFF; */
}
 
.flip-card-back {
    background-color: #EBEDFF;
    /* color: blue; */
    transform: rotateY(180deg);
}
 
.card-content {
    padding: 20px;
    text-align: center;
}
.scrollable-p {
  max-width: 36rem; /* Set maximum width */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Add ellipsis (...) to indicate truncated text */
  white-space: nowrap; /* Prevent text wrapping */
  cursor: pointer; /* Add pointer cursor for indicating clickable element */
}

.scrollable-p.expanded {
  white-space: normal; /* Revert to normal white-space for displaying full content */
}

.cube-container {
  width: 74rem;
    height: 24.5rem;
  position: relative;
  perspective: 100rem;

}

.cube {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
}

.cube-face {
  width: 30rem;
    height: 24.5rem;
  position: absolute;
  
}

.cube-face-front {
  width: 39rem;
  height: 24.5rem;
  position: absolute;
  transform: rotateY(0deg) translateZ(0rem) translateX(0rem);
  transition: transform 1s;
}



.cube-face-right {
  background-color: #F6F8FF;
  transform: rotateY(27.5deg) translateZ(10rem) translateX(33.5rem);
  transition: transform 1s;
}
@keyframes rotateFromBackleft {
  0% {
    transform: rotateY(180deg) translateZ(10rem) translateX(33.5rem);
  }
  100% {
    transform: rotateY(27.5deg) translateZ(10rem) translateX(33.5rem);
  }
}


@keyframes rotateFromBackright {
  0% {
    transform: rotateY(-180deg) translateZ(10rem) translateX(-33.5rem);
  }
  100% {
    transform: rotateY(-27.5deg) translateZ(10rem) translateX(-33.5rem);
  }
}

.cube-face-left {
  background-color: #F6F8FF;
  transform: rotateY(-27.5deg) translateZ(10rem) translateX(-33.5rem);
  transition: transform 1s;
}
