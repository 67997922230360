.header {
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 20;
    margin-bottom: 0.5rem;
    margin: 0 auto;
    padding: 0 0.6rem;
    text-align: center;
}
  
  .nav {
   
    align-items: center;
    position: relative;
    height: 3rem;
    margin: 0.75rem 1rem;
    padding: 1rem 0;
  }
  
  .nav__logo {
    color: black;
    transition: color 0.4s;
    /* letter-spacing: 0.2cm; */
    font-weight: 600;
    font-size: 2.5rem;
  }
  
  .nav__toggle,
  .nav__close {
    font-size: 1.5rem;
    color: black;

  }
  

  
  @media screen and (max-width: 1150px) {
    .nav__menu {
      position: fixed;
      top: 0;
      left: -100%;
      background-color: white ;
      width: 80%;
      height: 100%;
      padding: 0.5rem 1.5rem 1rem 2rem ;
      transition: left 0.4s;
    }
    .nav__link{
      font-size: 1.25rem;
    }

  }
  @media screen and (max-width: 490px) {
    .nav__menu {
      position: fixed;
      top: 0;
      left: -100%;
      background-color: white ;
      width: 100%;
      height: 100%;
      padding: 0.5rem 1rem 1rem 1rem;
      transition: left 0.4s;

    }
  
    .nav__list {
      row-gap: 1rem;
      padding: 1rem 1rem;
    }
  
    .nav__link {
      font-size: 1.18rem;
    }


  }
  
  .nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    align-items: flex-start;
  }
  
  .nav__link {

    font-weight: 500;
    transition: color 0.4s;
  }
  
  .nav__link:hover {
    color: #2E3D79;

  }
  
  .nav__close {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
  
  .show-menu {
    left: 0;
  }
  .nav__listt {
    display: flex;
    flex-direction: row;
    column-gap: 1.5rem;
  }
  
  .nav__menu_mobile {
    position: fixed;
    top: 0;
    left: -100%;
    background-color: white;

    width: 100%;
    height: 100%;
    padding: 6rem 3rem 0;
    transition: left 0.4s;
  }
  @media screen and (min-width: 1150px) {
    .nav {
      height: 4rem;
      padding: 0 0;
    }
    .nav__toggle,
    .nav__close {
      display: none;
    }
  
    .nav__list {
      flex-direction: row;
      column-gap: 1.5rem;
    }
    .nav__link{
      font-size: 1.15rem;
    }
  }
  
  .nav__list__web {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
    list-style-type: none;
    padding: 0;
  }
  